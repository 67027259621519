'use strict';

import { DateTime } from 'luxon';
import { ServerConstants } from '../../../../../../core/serverconstants';
import { DateFormatService } from '../../../../../../core/services/dateformat.service';
import * as _ from 'lodash';
import { CtaValidation } from '../common/ctaValidation';
import { CommunicationService } from '../../../../../../core/dataservices/communication.service';
import { CardChannelModel } from './cardChannelModel';
import { ForumService } from '../../../../../../core/dataservices/forum.service';
import { ConfigurationService } from 'src/app/core/dataservices/configuration.service';
import { IFileConfigurationResponse } from 'src/app/core/contracts/configuration.contracts';

export class CardController {
  static $inject = [
    'dateFormatService', 'serverConstants', 'ctaValidation', '$scope',
    'communicationservice', '$stateParams', 'logger', 'forumservice',
    'iscConfigurationService'];

  wizardStep;
  wizardSubstep;
  channelType;
  form;
  validationConstants = this.serverConstants.validationConstants;
  communicationChannelConstants = this.serverConstants.communicationChannelConstants;
  removeLink;
  stopValidation;
  minDateTime: DateTime;
  displayTabOptions = false;
  fileValidationErrors = [];
  readonly model = new CardChannelModel(() => this.checkTabOptionsVisibility());
  communication;
  isReadOnly;
  isCompleted;
  isCardWithStimuli;
  endDateEnabledConstants = {
    No: 0,
    Yes: 1,
  };
  endDateEnabled = 0;
  saveNotApplicable = false;
  saveCallback = () => this.saveData(this);
  navigationErrorMessage = '<p>It seems there are still some unresolved errors :</p>$errors<p>Please review and correct these before you leave.</p>';
  resetFormCallback = () => this.resetForm();
  fileConfiguration: IFileConfigurationResponse[] = [];

  constructor(
    private dateFormatService: DateFormatService,
    private serverConstants: ServerConstants,
    private ctaValidation: CtaValidation,
    private $scope: ng.IScope,
    private communicationservice: CommunicationService,
    private $stateParams: ng.ui.IStateParamsService,
    private logger: Logger,
    private forumservice: ForumService,
    private iscConfigurationService: ConfigurationService,
  ) {
    this.minDateTime = dateFormatService.startOfDay();
  }

  ideastream = {
    isSsoEnabled: false,
  };

  showCancelButton = false;

  $onInit() {
    this.removeLink = this.wizardStep.linkComponent(`channel-${this.channelType}`, this);
    this.iscConfigurationService.getFileConfiguration().then((response) => this.fileConfiguration = response);
    this.$loadData(this.communication);
    this.stopValidation = this.ctaValidation.setupValidation(
      this.$scope,
      () => this.form.CallToActionText,
      () => this.form.EditCTAUrl);

    this.isCardWithStimuli = this.checkIsCardWithStimuli();
  }

  $onDestroy() {
    this.removeLink();
    this.stopValidation();
  }

  $wizardStepLoaded() {
    const data = this.communication.Channels[0];
    if (data !== undefined && this.model.Guid !== undefined) {
      this.model.Guid = data.Channel.Guid;
    }
  }

  $wizardStepIsCompleted() {
    this.isCompleted = true;
    let message;
    this.isCardWithStimuli = this.checkIsCardWithStimuli();

    if (this.model.Message) {
      message = this.model.Message.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '');
    }

    if (this.model.Title === '' ||
      (!message && !this.isCardWithStimuli)) {
      this.isCompleted = false;
    }

    return this.isCompleted;
  }

  $wizardIsValid() {
    return !_.isUndefined(this.form) && this.isCompleted && this.form.$valid;
  }

  $wizardNextLabel() {
    let label = '';
    if (this.isReadOnly) {
      label = 'Continue';
    } else {
      label = 'Save and continue';
    }
    return label;
  }

  $wizardNextDescription() {
    if (!this.isReadOnly && this.communication.Channels[0].Channel.IsPublished) {
      return '';
    }
    return this.serverConstants.squareConstants.wizardNotPublishedStatus;
  }

  async $wizardBeforeNext() {
    await this.saveData(this);
  }

  resetForm() {
    this.$loadData(this.communication);
    this.form.$setPristine();
  }

  async saveData(root: this) {
    const request = {
      Channel: {
        Guid: root.model.Guid,
        Title: root.model.Title,
        CommunicationGuid: root.communication.Communication.Guid,
        ChannelType: root.serverConstants.channelTypeConstants.communicationCard,
        Message: root.model.Message,
        CallToActionText: root.model.CallToActionText,
        CallToActionUrl: root.model.CallToActionText ? root.model.CallToActionUrl : '',
        EditUrl: root.model.EditCTAUrl,
        Stimuli: [],
        StimuliArray: root.model.Stimuli,
        StartDateTime: root.dateFormatService.getDateTimeForSaving(root.model.StartDateTime, root.model.StartDateOffset),
        StartDateOffset: root.model.StartDateOffset,
        EndDateTime: root.endDateEnabled === 1 ?
          root.dateFormatService.getDateTimeForSaving(root.model.EndDateTime, root.model.EndDateOffset) :
          null,
        EndDateOffset: root.endDateEnabled === 1 ?
          root.model.EndDateOffset :
          null,
        Sticky: root.model.Sticky,
        OpenInNewTab: root.model.OpenInNewTab,
        ShowOnHomepage: true,
      },
    };
    root.model.Stimuli.forEach((stimulus) => stimulus.file = stimulus.imgUrl);
    const stimuliAndFiles = await this.forumservice.prepareStimuliAndNewStimuli(
      root.model.Stimuli, true);
    request.Channel.StimuliArray = stimuliAndFiles.stimuli;
    stimuliAndFiles.files.forEach((f) => request.Channel.Stimuli.push(stimuliAndFiles.files.indexOf(f)));
    const arg = { request: angular.toJson(request), files: stimuliAndFiles.files };

    await root.communicationservice.updateEngagement(arg).then(async () => {
      const response = await root.communicationservice.selectCommunication(root.$stateParams.communicationGuid);
      root.communication = response;
      root.$loadData(response);
      root.form.$setPristine();

      if (!root.isReadOnly && root.communication.Channels[0].Channel.IsPublished) {
        root.logger.success('Channel successfully updated.');
      }
    }, () => {
      root.logger.error('Channel not successfully updated.');
    });
  }

  $loadData(communication) {
    const data = communication.Channels[0];
    if (data !== undefined) {
      this.model.Guid = data.Channel.Guid;
      this.model.Title = (data.Channel.Title !== undefined && data.Channel.Title !== '') ? data.Channel.Title : communication.Communication.Title;
      this.model.CallToActionText = data.Channel.CallToActionText;
      this.model.CallToActionUrl = data.Channel.CallToActionUrl ? data.Channel.CallToActionUrl : 'https://';
      this.model.StartDateTime = this.dateFormatService.getDateTime(data.Channel.StartDateTime || DateTime.now(),
        data.Channel.StartDateOffset || this.model.StartDateOffset);
      this.model.StartTime = this.model.StartDateTime;
      this.model.StartDateOffset = data.Channel.StartDateOffset || this.model.StartDateOffset;
      this.endDateEnabled = data.Channel.EndDateTime !== null ? 1 : 0;
      if (this.endDateEnabled) {
        this.model.EndDateTime = this.dateFormatService.getDateTime(data.Channel.EndDateTime || DateTime.now(),
          data.Channel.EndDateOffset || this.model.EndDateOffset);
        this.model.EndDateOffset = data.Channel.EndDateOffset || this.model.EndDateOffset;
      } else {
        this.model.EndDateTime = this.dateFormatService
          .getDateTime(data.Channel.StartDateTime || DateTime.now(), this.model.EndDateOffset)
          .plus({ months: 1 });
        this.model.EndDateOffset = data.Channel.EndDateOffset || this.model.EndDateOffset;
      }
      this.model.EndTime = this.model.EndDateTime;
      this.model.Message = data.Channel.Message;
      this.model.Sticky = !!data.Channel.StickyDate; // Sticky is a datetime so convert it to bool
      this.model.EditCTAUrl = data.Channel.EditUrl;
      this.saveNotApplicable = this.communication.Channels[0] && this.communication.Channels[0].Channel.IsPublished;
      this.model.Stimuli = [];
      if (data.Stimulus != null) {
        this.model.Stimuli.push({
          source: 'db',
          action: 'ignore',
          imgUrl: data.Stimulus.ThumbnailUrl,
          type: data.Stimulus.FileType,
          Guid: data.Stimulus.Guid,
        });
        this.model.Stimuli = _.uniqBy(this.model.Stimuli, 'Guid');
      }
      this.model.OpenInNewTab = data.Channel.OpenInNewTab;
    } else {
      this.model.Title = communication.Communication.Title;
    }
    this.checkTabOptionsVisibility();
    this.ideastream.isSsoEnabled = this.model.CallToActionUrl && this.model.CallToActionUrl !== this.model.EditCTAUrl;
    this.minDateTime = this.dateFormatService.startOfDay(this.dateFormatService.getMinDate(DateTime.now(), this.model.StartDateTime));
  }

  private checkTabOptionsVisibility() {
    this.displayTabOptions = !!(this.form !== undefined && this.form.EditCTAUrl.$valid && this.form.EditCTAUrl.$valid
      && this.model.CallToActionText && this.model.EditCTAUrl);
  }

  private setDateOrTimeValidity(field: any, value: boolean) {
    if (!value) {
      field.$error.beforestart = true;
      field.$invalid = true;
    } else {
      field.$error = {};
      field.$invalid = false;
    }
  }

  private checkIsCardWithStimuli(): boolean {
    let result = false;

    if (this.model && this.model.Stimuli) {
      const cardStimuli = this.model.Stimuli.filter((s) => s.action === 'ignore');
      result = cardStimuli && cardStimuli.length > 0;
    }

    return result;
  }

  private startDateTimeChange() {
    if (this.model.StartTime) {
      this.model.StartDateTime = this.model.StartDateTime.set({ hour: this.model.StartTime.hour, minute: this.model.StartTime.minute });
    }

    const valid = !this.model.EndDateTime || this.model.EndDateTime >= this.model.StartDateTime;
    this.setDateOrTimeValidity(this.form.dateField, valid);
    this.setDateOrTimeValidity(this.form.timeField, valid);
    if (valid && this.form.endDateField) {
      this.setDateOrTimeValidity(this.form.endDateField, valid);
      this.setDateOrTimeValidity(this.form.endTimeField, valid);
    }
  }

  private endDateTimeChange() {
    // There`s apparently a bug / issue in moment / iscui / something
    // If one deletes all data from the control, the "required" gets in place and
    // If one chooses a new date / time, the new value will have the offset of the machine
    // And NOT the UTC offset as all must have

    if (this.model.EndTime) {
      this.model.EndDateTime = this.model.EndDateTime.set({ hour: this.model.EndTime.hour, minute: this.model.EndTime.minute });
    }

    const valid = !this.model.EndDateTime || this.model.EndDateTime >= this.model.StartDateTime;
    this.setDateOrTimeValidity(this.form.endDateField, valid);
    this.setDateOrTimeValidity(this.form.endTimeField, valid);
    if (valid) {
      this.setDateOrTimeValidity(this.form.dateField, valid);
      this.setDateOrTimeValidity(this.form.timeField, valid);
    }
  }

}


